import { Container } from 'inversify'

const container = new Container()
export default container

export const SERVICE_IDENTIFIERS = {
  Axios: Symbol('Axios'),
  IPostalCodeService: Symbol('IPostalCodeService'),
  IPropositionService: Symbol('IPropositionService'),
  IChildBenefitCalculatorService: Symbol('IChildBenefitCalculatorService'),
  IBookingService: Symbol('IBookingService'),
  IOfferService: Symbol('IOfferService'),
  ISchoolServiceService: Symbol('ISchoolServiceService'),
  IServiceSettingsService: Symbol('IServiceSettingsService'),
  IConfigurationService: Symbol('IConfigurationService'),
  IFeatureService: Symbol('IFeatureService'),
  IEmailService: Symbol('IEmailService'),
  IWaitingListService: Symbol('IWaitingListService'),
  ISubscriptionService: Symbol('ISubscriptionService'),
  IShareService: Symbol('IShareService'),
  IProductService: Symbol('IProductService')
}
