
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { NAMESPACES, STATE } from '@/store'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import PartouSocials from '@/components/PartouComponents/PartouSocials.vue'
import Page from '../Page'
import i18n from '@/plugins/i18n'

const configurationModule = namespace(NAMESPACES.configuration)

@Component({
  components: {
    PartouButton,
    PartouSocials
  },
  metaInfo () {
    return {
      title: (this as any).pageTitle, // eslint-disable-line @typescript-eslint/no-explicit-any
      meta: [
        { property: 'title', content: (this as any).pageTitle }, // eslint-disable-line @typescript-eslint/no-explicit-any
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  }
})
export default class NotFoundPage extends Vue implements Page {
  pageTitle = i18n.t('pageTitles.notFound').toString()

  @configurationModule.State(STATE.configuration.configuration)
  configuration! : Record<string, any>  /* Disabled because of any */// eslint-disable-line

  goToPartou () : void {
    window.location.href = process.env.VUE_APP_PARTOU_HOME_PAGE! /* Must be set */// eslint-disable-line @typescript-eslint/no-non-null-assertion
  }
}
