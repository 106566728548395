
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class PageHeader extends Vue {
  @Prop({ required: false, default: true })
  showLogo? : boolean

  @Prop({ required: false, default: true })
  showOverlay? : boolean

  @Prop({ required: false, default: 'header-overlay-mask.svg' })
  overlaySrc? : string

  @Prop({ required: false, default: '' })
  overlayClassName?: string

  homePage: string = process.env.VUE_APP_PARTOU_HOME_PAGE! /* Must be set */// eslint-disable-line @typescript-eslint/no-non-null-assertion

  onLogoClicked (): void {
    window.location.href = this.homePage
  }
}
