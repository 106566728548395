
import { DayOfWeek } from '@/models'
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator'
import PartouCheckBox from '@/components/PartouComponents/Input/PartouCheckBox/PartouCheckBox.vue'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'
import PartouDayField from './PartouDayField.vue'
import DayCheckboxType from './DayCheckboxType'
import DayCheckboxState from './DayCheckboxState'
import DaySelectorCheckboxesFactory from './DaySelectorCheckboxesFactory'
import { sendWaitingListSelectedAnalyticsEvent } from '@/plugins/googleAnalytics/gtagFunctions'

@Component({
  components: { PartouCheckBox, PartouDialog, PartouDayField }
})
export default class DaySelector extends Vue {
  @Prop({ required: false, default: () => DaySelectorCheckboxesFactory.constructCheckboxes() })
  value!: DayCheckboxState[]

  @Prop({ required: false, default: false })
  isRequired!: boolean

  @Prop({ required: false, default: true })
  showLabelsBelow! : boolean

  @Prop({ required: false, default: false })
  isReadOnly! : boolean

  @Prop({ required: false, default: false })
  mustSelectOne!: boolean

  @Prop({ required: false, default: 0 })
  numberOfDaysSelected!: number

  @Prop({ required: false, default: false })
  withPriority! : boolean

  @Prop({ required: false })
  title?: string

  @Prop({ required: false, default: false })
  isWaitingListOnly?: boolean

  formIsValid = true
  DayCheckboxType = DayCheckboxType

  textValue = ''
  isValid = false

  itemWidth = 44
  itemMargin = 16
  isWaitingListConfirmVisible = false
  lastSelectedWaitingListDay?: DayCheckboxState
  errorTranslationString = ''

  // If no user input is registered yet, don't show the initial error message
  initial = true
  rules = [this.checkRequired]

  checkRequired (): boolean | string {
    this.formIsValid = !this.isRequired ? true : this.value.some(d => d.isChecked)
    return this.formIsValid
  }

  @Watch('value', { immediate: true })
  onCheckboxesChanged () : void {
    this.value.forEach(checkbox => {
      checkbox.label = this.getDayLabel(checkbox.day)
    })
  }

  onWaitingListAccepted (): void {
    this.isWaitingListConfirmVisible = false
    sendWaitingListSelectedAnalyticsEvent(true)
    if (this.lastSelectedWaitingListDay) {
      // Get day from value because reference could be updated
      const day = this.value.find(x => x.day === this.lastSelectedWaitingListDay?.day)
      if (day) {
        this.emitOnDayChange(day)
        this.lastSelectedWaitingListDay = undefined
      }
    }
  }

  onWaitingListCancelled (): void {
    sendWaitingListSelectedAnalyticsEvent(false)
    // Reset toggle to false if dialog is not confirmed
    if (this.lastSelectedWaitingListDay) {
      this.lastSelectedWaitingListDay.isChecked = false
      this.lastSelectedWaitingListDay = undefined
    }

    this.isWaitingListConfirmVisible = false
  }

  @Emit('input')
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSelectionChanged (): DayCheckboxState[] {
    return this.value
  }

  getDayLabel (day: DayOfWeek): string {
    switch (day) {
    case DayOfWeek.Monday:
      return this.$t('days.monday').toString().substr(0, 2).toLowerCase()
    case DayOfWeek.Tuesday:
      return this.$t('days.tuesday').toString().substr(0, 2).toLowerCase()
    case DayOfWeek.Wednesday:
      return this.$t('days.wednesday').toString().substr(0, 2).toLowerCase()
    case DayOfWeek.Thursday:
      return this.$t('days.thursday').toString().substr(0, 2).toLowerCase()
    case DayOfWeek.Friday:
      return this.$t('days.friday').toString().substr(0, 2).toLowerCase()
    case DayOfWeek.Saturday:
      return this.$t('days.saturday').toString().substr(0, 2).toLowerCase()
    case DayOfWeek.Sunday:
      return this.$t('days.sunday').toString().substr(0, 2).toLowerCase()
    }
  }

  validate () : void {
    if (!this.checkRequired()) {
      this.errorTranslationString = 'daySelector.errorMsgMinimumSelected'
    } else {
      this.errorTranslationString = ''
    }
  }

  onDayChange (day: DayCheckboxState) : void {
    if (!this.isWaitingListOnly && !day.isChecked && day.type === DayCheckboxType.Wait) {
      this.lastSelectedWaitingListDay = day
      this.isWaitingListConfirmVisible = true
    } else {
      this.emitOnDayChange(day)
    }
  }

  @Emit('onDayChange')
  emitOnDayChange (day: DayCheckboxState) : number {
    this.initial = false
    let numberOfDays = this.numberOfDaysSelected

    if (this.mustSelectOne && day.isChecked && this.value.filter(d => d.isChecked).length <= 1) {
      return numberOfDays
    }

    day.isChecked = !day.isChecked
    day.priority = this.getPriority(day)

    numberOfDays = this.numberOfDaysSelected + (day.isChecked ? 1 : -1)

    this.onSelectionChanged()
    this.validate()
    return numberOfDays
  }

  getPriority (day: DayCheckboxState) : number | undefined {
    if (!day.isChecked) {
      return undefined
    }

    if (!this.withPriority) {
      return day.priority
    }

    // Returns the next highest priority based on the other days
    const highestPriority = Math.max(...this.value.map(x => x.priority ?? 0))
    return highestPriority + 1
  }

  getIsReadOnlyState (day: DayCheckboxState) : boolean {
    return this.isReadOnly || (day.isReadOnly ?? false)
  }

  getOrderedDayCheckboxes () : DayCheckboxState[] {
    const orderedDayOfWeekList: DayOfWeek[] = [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday]
    const orderedDayCheckBoxes: DayCheckboxState[] = []

    orderedDayOfWeekList.forEach(day => {
      const dayCheckboxState = this.value.find(x => x.day === day)
      if (dayCheckboxState) {
        orderedDayCheckBoxes.push(dayCheckboxState)
      }
    })

    return orderedDayCheckBoxes
  }
}
