import { GetActiveSubscriptionsByServiceIdDocument, GetActiveSubscriptionsByServiceIdQuery, GetActiveSubscriptionsByServiceIdQueryVariables, SubscriptionService as SubscriptionServiceModel } from '@/models'
import ApolloClient from 'apollo-client'
import { inject, injectable } from 'inversify'
import ISubscriptionService from './ISubscriptionService'

@injectable()
export default class SubscriptionService implements ISubscriptionService {
  private apollo: ApolloClient<unknown>

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  async getActiveSubscriptionsByServiceId (variables: GetActiveSubscriptionsByServiceIdQueryVariables): Promise<Partial<SubscriptionServiceModel>[]> {
    const result = await this.apollo.query<GetActiveSubscriptionsByServiceIdQuery>({
      query: GetActiveSubscriptionsByServiceIdDocument,
      variables
    })

    return result.data.subscription_service as [] ?? []
  }
}
