import ApolloClient from 'apollo-client'
import { inject, injectable } from 'inversify'
import IServiceSettingsService from './IServiceSettingsService'
import { GetServiceSettingsByServiceIdDocument, GetServiceSettingsByServiceIdQuery, ServiceSettings } from '@/models'

@injectable()
export default class ServiceSettingsService implements IServiceSettingsService {
  private apollo: ApolloClient<unknown>

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  async getServiceSettingsByServiceIdAsync (serviceId : string): Promise<ServiceSettings> {
    const result = await this.apollo.query<GetServiceSettingsByServiceIdQuery>({
      query: GetServiceSettingsByServiceIdDocument,
      variables: { serviceId }
    })
    return result.data.service_settings[0] as ServiceSettings
  }
}
