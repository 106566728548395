
import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'
import PartouSocials from '@/components/PartouComponents/PartouSocials.vue'
import PartouCheckBox from '@/components/PartouComponents/Input/PartouCheckBox/PartouCheckBox.vue'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoCompleteN.vue'
import PartouDayField from '@/components/InputFields/DaySelector/PartouDayField.vue'
import { NAMESPACES, STATE } from '@/store'
import { namespace } from 'vuex-class'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import IConfigurationService from '@/services/ConfigurationService/IConfigurationService'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import DayOfWeek from '@/models/enums/DayOfWeek'
import { sendSubscriptionChosenAnalyticsEvent } from '@/plugins/googleAnalytics/gtagFunctions'
import { ROUTES } from '@/router/routes'
import IWaitingListService from '@/services/WaitingListService/IWaitingListService'
import PartouFloatingCircleButton from '@/components/PartouComponents/Buttons/PartouFloatingCircleButton.vue'
import { ServiceKind } from '@/models'

const userSelectionModule = namespace(NAMESPACES.userSelection)
const configurationModule = namespace(NAMESPACES.configuration)

@Component({
  components: { PartouDialog, PartouSocials, PartouCheckBox, PartouButton, PartouAutocomplete, PartouDayField, PartouFloatingCircleButton }
})
export default class WaitingListPartiallyDeclinedDialog extends Vue {
  @configurationModule.State(STATE.configuration.configuration)
  configuration! : Record<string, any>  /* Disabled because of any */// eslint-disable-line

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @Prop({ required: true })
  declineEntireOffer!: boolean

  @Prop({ required: true })
  declinedDaysFromProposition!: { NSO?: DayOfWeek[], VSO?: DayOfWeek[], KDV?: DayOfWeek[] }

  @Prop({ required: false })
  bookingHash!: string

  @Prop({ required: true })
  serviceSlug!: string

  @VModel({ required: true })
  showDialog! : boolean

  loading = false
  retainWaitingList = true
  customCausedByReason = ''
  causedByReason = ''
  causedByReasons: string[] = []
  showCustomCausedByReason = false
  waitingListService!: IWaitingListService

  created () : void {
    this.waitingListService = container.get<IWaitingListService>(SERVICE_IDENTIFIERS.IWaitingListService)
    this.getConfigurationTableDataAsync()
  }

  async getConfigurationTableDataAsync (): Promise<void> {
    const configurationService = container.get<IConfigurationService>(SERVICE_IDENTIFIERS.IConfigurationService)
    const result = await configurationService.getConfigurationByKeyAsync<string[]>({ key: 'parent_proposition_decline_reasons', date: new Date() })
    this.causedByReasons = result
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDeclineReasonChanged (value: string) : void {
    this.showCustomCausedByReason = value === 'Anders'
    if (this.causedByReasons.includes(value)) {
      this.causedByReason = value
    }
  }

  validateCustomCausedByReason (value: string) : string | boolean {
    if (value.length > 125) {
      return this.$t('waitingListFlow.dialog.partlyDecline.maximumCharacters').toString()
    } else if (value.trim() === '') {
      return this.$t('waitingListFlow.dialog.partlyDecline.requiredField').toString()
    }
    return true
  }

  invalidCausedByReason () : boolean {
    const invalidCausedByReason = this.customCausedByReason.trim() === '' || this.customCausedByReason.length > 125

    if (this.causedByReason === '') return true
    if (this.causedByReason === 'Anders' && invalidCausedByReason) return true

    return false
  }

  async onContinueClicked () {
    this.loading = true
    this.userSelectionFormState.causedByReason = undefined

    const removeDaysFromWaitingList = !this.retainWaitingList
    if (removeDaysFromWaitingList) {
      if (this.userSelectionFormState.selectedServiceKind === ServiceKind.SchoolCare) {
        delete this.declinedDaysFromProposition.KDV
      } else {
        delete this.declinedDaysFromProposition.NSO
        delete this.declinedDaysFromProposition.VSO
      }
      this.userSelectionFormState.removeFromWaitingListDaysPerServiceVariety = this.declinedDaysFromProposition
    } else {
      this.userSelectionFormState.removeFromWaitingListDaysPerServiceVariety = undefined
    }

    this.userSelectionFormState.causedByReason = this.causedByReason === 'Anders' ? this.customCausedByReason : this.causedByReason
    if (!this.declineEntireOffer) {
      sendSubscriptionChosenAnalyticsEvent()
      this.$router.push({ name: ROUTES.orderContactInfo, params: { serviceSlug: this.serviceSlug, bookingHash: this.bookingHash } })
      return
    }

    await this.waitingListService.declineProposedWaitingListPropositionAsync(this.bookingHash, this.userSelectionFormState.removeFromWaitingListDaysPerServiceVariety, this.userSelectionFormState.causedByReason)
    this.$router.push({ name: ROUTES.waitingListReactionReceived })
    this.loading = false
  }

  getDeclinedDaysString (declinedDays: { NSO?: DayOfWeek[], VSO?: DayOfWeek[], KDV?: DayOfWeek[] }) : string {
    const orderedDays = [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday, DayOfWeek.Saturday, DayOfWeek.Sunday]
    const distinctDeclinedDays = [...new Set([...declinedDays.NSO || [], ...declinedDays.VSO || [], ...declinedDays.KDV || []])]
    distinctDeclinedDays.sort((a, b) => orderedDays.indexOf(a) - orderedDays.indexOf(b))
    let result = ' '
    for (let i = 0; i < distinctDeclinedDays.length; i++) {
      if (i === 0) {
        // First element
        result += `<span><b>${this.getDayLabel(distinctDeclinedDays[i] as DayOfWeek)}</b></span>`
      } else if (i === distinctDeclinedDays.length - 1) {
        // Last element with prefix
        result += ' en ' + `<span><b>${this.getDayLabel(distinctDeclinedDays[i] as DayOfWeek)}</b></span>`
      } else {
        // Other elements with prefix
        result += ', ' + `<span><b>${this.getDayLabel(distinctDeclinedDays[i] as DayOfWeek)}</b></span>`
      }
    }
    result += '.'
    return result
  }

  getDayLabel (day: DayOfWeek): string {
    switch (day) {
    case DayOfWeek.Monday:
      return this.$t('days.monday').toString()
    case DayOfWeek.Tuesday:
      return this.$t('days.tuesday').toString()
    case DayOfWeek.Wednesday:
      return this.$t('days.wednesday').toString()
    case DayOfWeek.Thursday:
      return this.$t('days.thursday').toString()
    case DayOfWeek.Friday:
      return this.$t('days.friday').toString()
    case DayOfWeek.Saturday:
      return this.$t('days.saturday').toString()
    case DayOfWeek.Sunday:
      return this.$t('days.sunday').toString()
    }
  }

  @Emit('onCloseDialog')
  closeDialog () : void {
    // emits the closing of the dialog
  }
}
