
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import DayCheckboxState from './DayCheckboxState'
import DayCheckboxType from '@/components/InputFields/DaySelector/DayCheckboxType'

@Component({
  components: { }
})
export default class PartouDayField extends Vue {
  @Prop({ required: true })
  day!: DayCheckboxState

  @Prop({ required: true })
  isSelected!: boolean

  @Prop({ required: false, default: false })
  disabled! : boolean

  @Prop({ required: false, default: false })
  isReadOnly! : boolean

  @Prop({ required: false, default: false })
  isWaitingListOnly?: boolean

  @Prop({ required: false, default: true })
  showLabel! : boolean

  @Prop({ required: false, default: true })
  showDayName! : boolean

  @Prop({ required: false, default: false })
  renderAsCheckbox! : boolean

  animateMessageTimeout?: NodeJS.Timeout
  showMessageTimeout?: NodeJS.Timeout
  message?: string
  animateMessage = false
  showMessage = false
  dayCheckboxType = DayCheckboxType

  get ariaLabel () : string {
    let value = this.$t(`daySelector.ariaLabel.${this.day.day.toLowerCase()}`).toString()
    value += ' '
    value += this.$t(`daySelector.ariaLabel.${this.day.isChecked ? 'checked' : 'unchecked'}`)

    if (this.day.type === DayCheckboxType.Wait) {
      value += ' ' + this.$t('daySelector.ariaLabel.waitinglist')
    }
    return value
  }

  firstToUpper (dayLabel: string):string {
    return dayLabel[0].toUpperCase() + dayLabel.substring(1)
  }

  getIconName (dayCheckboxType: DayCheckboxType): string {
    if (this.isSelected) {
      switch (dayCheckboxType) {
      case DayCheckboxType.Check:
        return '$vuetify.icons.partouCheckNew'
      case DayCheckboxType.Wait:
        return '$vuetify.icons.partouHourglassNew'
      }
    }

    if (this.animateMessage && dayCheckboxType === DayCheckboxType.Closed) {
      return '$vuetify.icons.partouLockNew'
    }

    return ''
  }

  getMessage (dayCheckboxType: DayCheckboxType): string {
    if (dayCheckboxType === DayCheckboxType.Wait) {
      return this.$t('daySelector.wait').toString()
    }

    if (dayCheckboxType === DayCheckboxType.Closed) {
      return this.$t('daySelector.closed').toString()
    }

    return ''
  }

  @Watch('isSelected', { immediate: true })
  onIsSelectedChanged (newValue: boolean): void {
    // If it is not selected the messsage must fade away
    if (!newValue) {
      this.animateMessage = false
    }
  }

  onClick (): void {
    if (this.disabled) {
      return
    }

    if (this.isReadOnly) {
      return
    }

    if (!this.isSelected) {
      // Clear the timeouts so the message won't be removed to fast when you click the button repeatedly
      if (this.animateMessageTimeout) {
        clearTimeout(this.animateMessageTimeout)
      }
      if (this.showMessageTimeout) {
        clearTimeout(this.showMessageTimeout)
      }

      this.animateMessage = true
      this.showMessage = true
      // Set the text to fade away after 2 seconds
      this.animateMessageTimeout = setTimeout(() => {
        this.animateMessage = false
      }, 2000)
      // Set the text to dissapear after 3 seconds
      this.showMessageTimeout = setTimeout(() => {
        this.showMessage = false
      }, 3000)
    }

    // If the type is closed it will not be emitted because it can never be selected
    if (this.day.type !== DayCheckboxType.Closed) {
      this.$emit('onChange', this.day)
    } else {
      this.animateMessage = true
      this.animateMessageTimeout = setTimeout(() => {
        this.animateMessage = false
      }, 2000)
    }
  }
}
