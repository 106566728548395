
import { Vue, Component, Prop } from 'vue-property-decorator'
import PartouBottomSheet from '@/components/PartouComponents/PartouBottomSheet/PartouBottomSheet.vue'
import { GETTERS, ACTIONS, MUTATIONS, NAMESPACES, STATE } from '@/store'
import { namespace } from 'vuex-class'
import ICalculatorFormState from '@/store/modules/childBenefitCalculator/ICalculatorFormState'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import { ICalculatorGetters } from '@/store/modules/childBenefitCalculator'
import ICalculatorConstants from '@/store/modules/childBenefitCalculator/ICalculatorConstants'
import { euroFilter } from '@/utils/filters'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import { inputRegexDirective } from '@/utils/directives'
import PartouTextField from '@/components/PartouComponents/Input/PartouTextField/PartouTextField.vue'
import Debounce from '@/utils/decorators/debounceDecorator'
import PropositionSubscription from '@/models/PropositionSubscription'
import ChildBenefitSummaryHeader from './ChildBenefitSummaryHeader.vue'
import ChildBenefitSummaryTable from './ChildBenefitSummaryTable.vue'
import ChildBenefitCalculatorResult from './ChildBenefitCalculatorResult/ChildBenefitCalculatorResult.vue'
import { regexes } from '@/definitions'
import { sendUseCalculatorAnalyticsEvent } from '@/plugins/googleAnalytics/gtagFunctions'
import { ServiceVarietyName } from '@/models'

const childBenefitCalculatorModule = namespace(NAMESPACES.childBenefitCalculator)
const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: { PartouBottomSheet, PartouButton, PartouNotify, PartouTextField, ChildBenefitSummaryHeader, ChildBenefitSummaryTable, ChildBenefitCalculatorResult },
  directives: { inputRegexDirective },
  data: () => ({ regexes })
})
export default class ChildBenefitCalculator extends Vue {
  @Prop({ required: true })
  propositionSubscription?: PropositionSubscription

  @Prop({ required: false, default: false })
  displayCalculation! : boolean

  @Prop({ required: false, default: false })
  withWaitingListHours! : boolean

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  // #region store state
  @childBenefitCalculatorModule.State(STATE.childBenefitCalculator.formState)
  calculatorFormState! : ICalculatorFormState

  @childBenefitCalculatorModule.State(STATE.childBenefitCalculator.constants)
  calculatorConstants! : ICalculatorConstants

  @childBenefitCalculatorModule.Action(ACTIONS.childBenefitCalculator.fetchConstantsOnceAsync)
  fetchConstantsOnceAsync! : () => Promise<void>

  @childBenefitCalculatorModule.Mutation(MUTATIONS.childBenefitCalculator.setFormState)
  setFormState! : (formState: ICalculatorFormState) => void

  get serviceVarietyNames (): ServiceVarietyName[] { return this.userSelectionFormState?.selectedServiceVarieties || [] }
  get formattedCumulativeIncomePerYear (): string { return euroFilter(this.calculatorFormState?.cumulativeIncomePerYear) }

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getDeclarableHourlyRate)
  getDeclarableHourlyRate! : ICalculatorGetters['getDeclarableHourlyRate']

  get declarableHourlyRate (): number { return this.getDeclarableHourlyRate && this.getDeclarableHourlyRate({ serviceVarietyNames: this.serviceVarietyNames, offerHourlyRate: this.costsPerHour }) }
  get formattedDeclarableHourlyRate (): string { return euroFilter(this.declarableHourlyRate) }

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getOwnContributionPerMonth)
  getOwnContributionPerMonth! : ICalculatorGetters['getOwnContributionPerMonth']

  get ownContributionPerMonth (): number {
    return this.propositionSubscription
      ? this.getOwnContributionPerMonth({ serviceVarietyNames: this.serviceVarietyNames, offerHourlyRate: this.costsPerHour, propositionSubscription: this.propositionSubscription, withWaitingListHours: this.withWaitingListHours })
      : 0
  }

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getOwnContributionPerHour)
  getOwnContributionPerHour! : ICalculatorGetters['getOwnContributionPerHour']

  get ownContributionPerHour (): number {
    return this.propositionSubscription
      ? this.getOwnContributionPerHour({ serviceVarietyNames: this.serviceVarietyNames, offerHourlyRate: this.costsPerHour, propositionSubscription: this.propositionSubscription, withWaitingListHours: this.withWaitingListHours })
      : 0
  }

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getRemainingDeclarableCareHoursPerMonth)
  getRemainingDeclarableCareHoursPerMonth! : ICalculatorGetters['getRemainingDeclarableCareHoursPerMonth']

  get remainingDeclarableCareHoursPerMonth (): number {
    return this.propositionSubscription
      ? this.getRemainingDeclarableCareHoursPerMonth({ serviceVarietyNames: this.serviceVarietyNames, propositionSubscription: this.propositionSubscription, withWaitingListHours: this.withWaitingListHours })
      : 0
  }

  get careHoursPerMonth (): number {
    return this.propositionSubscription
      ? this.getCareHoursPerMonth({ propositionSubscription: this.propositionSubscription, withWaitingListHours: this.withWaitingListHours })
      : 0
  }

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getCareHoursPerMonth)
  getCareHoursPerMonth! : ICalculatorGetters['getCareHoursPerMonth']

  get costsPerHour (): number { return this.propositionSubscription?.pricePerHour ?? 0 }

  get costsPerMonth (): number { return this.propositionSubscription?.priceOfFirstFullMonth ?? 0 }

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getCompensatedPercentage)
  getCompensatedPercentage! : ICalculatorGetters['getCompensatedPercentage']

  get compensatedPercentage (): number { return this.getCompensatedPercentage && this.getCompensatedPercentage() }

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getBenefitsPerMonth)
  getBenefitsPerMonth! : ICalculatorGetters['getBenefitsPerMonth']

  get benefitsPerMonth (): number {
    return this.propositionSubscription
      ? this.getBenefitsPerMonth({ serviceVarietyNames: this.serviceVarietyNames, offerHourlyRate: this.costsPerHour, propositionSubscription: this.propositionSubscription, withWaitingListHours: this.withWaitingListHours })
      : 0
  }

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getBenefitsPerHour)
  getBenefitsPerHour! : ICalculatorGetters['getBenefitsPerHour']

  get benefitsPerHour (): number {
    return this.propositionSubscription
      ? this.getBenefitsPerHour({ serviceVarietyNames: this.serviceVarietyNames, offerHourlyRate: this.costsPerHour, propositionSubscription: this.propositionSubscription, withWaitingListHours: this.withWaitingListHours })
      : 0
  }

  get formattedBenefitsPerHour (): string { return euroFilter(this.benefitsPerHour) }
  // #endregion

  isCalculatorVisible = false
  isExtraInformationVisible = false
  currentYear = new Date().getFullYear()

  // info popup toggles
  isGeneralInfoVisible = false
  isCumulativeIncomeInfoVisible = false
  isWorkingHoursInfoVisible = false
  isBenefitAmountInfoVisible = false
  isMaximumHoursInfoVisible = false
  inputToFocusOn = ''
  hoursInWeek = 168

  async mounted (): Promise<void> {
    await this.fetchConstantsOnceAsync()
  }

  @Debounce(300)
  onDataChanged (resizeCallback: () => void): void {
    this.onFormDataChanged()
    resizeCallback()
  }

  onFormDataChanged (): void {
    this.setFormState(this.calculatorFormState)
  }

  toggleIsCalculatorVisible (): void {
    this.isCalculatorVisible = !this.isCalculatorVisible
    if (this.isCalculatorVisible) {
      sendUseCalculatorAnalyticsEvent()
    }
  }

  toggleExtraInformationVisible (): void {
    this.isExtraInformationVisible = !this.isExtraInformationVisible
  }

  onCumulativeIncomeInfoClicked (): void {
    this.isCumulativeIncomeInfoVisible = true
  }

  onWorkingHoursInfoClicked (): void {
    this.isWorkingHoursInfoVisible = true
  }

  onBenefitAmountInfoClicked (): void {
    this.isBenefitAmountInfoVisible = true
  }

  onMaximumHoursInfoClicked (): void {
    this.isMaximumHoursInfoVisible = true
  }

  focusInput (inputToFocusOn: string | undefined) : void {
    // Handle it the next tick to wait for the resize to be completed
    Vue.nextTick(() => {
      const component = this.$refs[inputToFocusOn ?? this.inputToFocusOn] as Vue
      if (component) {
        const element = component.$refs['text-field'] as HTMLElement
        if (element) {
          element.focus()
        }
      }
    })
  }

  getPopupWidth () : number {
    const width = window.innerWidth - 96
    return width > 520 ? 520 : width
  }
}
