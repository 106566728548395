import IUserSelectionState from './IUserSelectionState'
import IUserSelectionFormState from './IUserSelectionFormState'
import Vue from 'vue'
import { MutationTree } from 'vuex'
import { state as createDefaultState } from './index'
import { School, SchoolGroup, SubscriptionService, DayOfWeek, ServiceKind, PostalCode, Service, ServiceVarietyName } from '@/models'
import DaySelectionState from '@/models/types/DaySelectionState'
import { getIsValidStartDateOfCare } from './getters/getIsValidStartDateOfCare'

export const MUTATIONS = {
  setFormState: 'setFormState',
  setDateOfBirth: 'setDateOfBirth',
  setStartDate: 'setStartDate',
  setState: 'setState',
  resetState: 'resetState',
  setCurrentStep: 'setCurrentStep',
  setSelectedService: 'setSelectedService',
  setSelectedServiceVarieties: 'setSelectedServiceVarieties',
  setSelectedSchool: 'setSelectedSchool',
  setSelectedSchoolGroup: 'setSelectedSchoolGroup',
  resetServiceVarietySelectedDays: 'resetServiceVarietySelectedDays',
  setSelectedSubscriptionService: 'setSelectedSubscriptionService',
  setProposedSubscriptionService: 'setProposedSubscriptionService',
  setSelectedSchoolLocality: 'setSelectedSchoolLocality',
  setSelectedPropositionId: 'setSelectedPropositionId',
  setPostalCode: 'setPostalCode',
  setServiceVarietyDaysAsync: 'setServiceVarietyDaysAsync',
  emptySelectedServiceVarietyDaysForServiceVariety: 'emptySelectedServiceVarietyDaysForServiceVariety',
  setSelectedServiceKind: 'setSelectedServiceKind',
  setPostalCodeValidationPending: 'setPostalCodeValidationPending',
  setPostalCodeValidationIsValid: 'setPostalCodeValidationIsValid',
  setIsUsingChildbenefitCalculator: 'setIsUsingChildbenefitCalculator',
  setSelectedProductIdsForService: 'setSelectedProductIdsForService'
}

export function mutations (): MutationTree<IUserSelectionState> {
  return {
    setSelectedService (state: IUserSelectionState, selectedService: Service | undefined) {
      Vue.set(state.formState, 'selectedService', selectedService)
      Vue.set(state, 'formIsValid', isFormValid(state))
    },
    setSelectedServiceKind (state: IUserSelectionState, selectedServiceKind: ServiceKind) {
      Vue.set(state.formState, 'selectedServiceKind', selectedServiceKind)
      Vue.set(state, 'formIsValid', isFormValid(state))
    },
    setSelectedPropositionId (state: IUserSelectionState, setSelectedPropositionId: string) {
      Vue.set(state.formState, 'selectedPropositionId', setSelectedPropositionId)
      Vue.set(state, 'formIsValid', isFormValid(state))
    },
    setSelectedSubscriptionService (state: IUserSelectionState, selectedSubscription: SubscriptionService) {
      Vue.set(state.formState, 'selectedSubscription', selectedSubscription)
      Vue.set(state, 'formIsValid', isFormValid(state))
    },
    setProposedSubscriptionService (state: IUserSelectionState, proposedSubscription: SubscriptionService) {
      Vue.set(state.formState, 'proposedSubscription', proposedSubscription)
      Vue.set(state, 'formIsValid', isFormValid(state))
    },
    setSelectedSchoolLocality (state: IUserSelectionState, selectedSchoolLocality : string) {
      Vue.set(state.formState, 'selectedSchoolLocality', selectedSchoolLocality)
      Vue.set(state, 'formIsValid', isFormValid(state))
    },
    setSelectedSchool (state: IUserSelectionState, selectedSchool : School) {
      Vue.set(state.formState, 'selectedSchool', selectedSchool)
      Vue.set(state, 'formIsValid', isFormValid(state))
    },
    setSelectedSchoolGroup (state: IUserSelectionState, selectedSchoolGroup : SchoolGroup) {
      Vue.set(state.formState, 'selectedSchoolGroup', selectedSchoolGroup)
      Vue.set(state, 'formIsValid', isFormValid(state))
    },
    setSelectedServiceVarieties (state: IUserSelectionState, selectedServiceVarieties: ServiceVarietyName[]) {
      Vue.set(state.formState, 'selectedServiceVarieties', selectedServiceVarieties)
      Vue.set(state, 'formIsValid', isFormValid(state))
    },
    setPostalCode (state: IUserSelectionState, postalCode : PostalCode) {
      Vue.set(state.formState, 'postalCode', postalCode)
    },
    setDateOfBirth (state: IUserSelectionState, date : Date) {
      Vue.set(state.formState, 'dateOfBirth', date)

      const isValidStartDate = getIsValidStartDateOfCare(state)

      if (!isValidStartDate) {
        Vue.set(state.formState, 'startDateOfDayCare', undefined)
      }
    },
    setStartDate (state: IUserSelectionState, date : Date) {
      Vue.set(state.formState, 'startDateOfDayCare', date)
    },
    setFormState (state: IUserSelectionState, formState: Partial<IUserSelectionFormState>) {
      Vue.set(state, 'formState', { ...state.formState, ...formState })
      Vue.set(state, 'formIsValid', isFormValid(state))
    },
    setCurrentStep (state: IUserSelectionState, currentStep: number) {
      Vue.set(state, 'currentStep', currentStep)
    },
    setState (state: IUserSelectionState, newState: IUserSelectionState) {
      Object.assign(state, newState)
    },
    resetState (state: IUserSelectionState) {
      Object.assign(state, createDefaultState())
    },
    setServiceVarietyDaysAsync (state: IUserSelectionState, selectedServiceVarietydays : Record<ServiceVarietyName, DaySelectionState[]>) {
      Vue.set(state.formState, 'daysPerServiceVariety', selectedServiceVarietydays)
    },
    setIsUsingChildbenefitCalculator (state :IUserSelectionState, value : boolean) {
      Vue.set(state.formState, 'isUsingChildbenefitCalculator', value)
    },
    setPostalCodeValidationPending (state : IUserSelectionState, pending: boolean) {
      if (state.formState.postalCodeValidationState) {
        Vue.set(state.formState.postalCodeValidationState, 'pending', pending)
      } else {
        Vue.set(state.formState, 'postalCodeValidationState', { pending })
      }
    },
    setPostalCodeValidationIsValid (state : IUserSelectionState, isValid: boolean) {
      if (state.formState.postalCodeValidationState) {
        Vue.set(state.formState.postalCodeValidationState, 'isValid', isValid)
      } else {
        Vue.set(state.formState, 'postalCodeValidationState', { isValid })
      }
    },
    emptySelectedServiceVarietyDaysForServiceVariety (state: IUserSelectionState, serviceVariety: ServiceVarietyName) {
      for (const x in state.formState.daysPerServiceVariety) {
        if (x.toString() === serviceVariety) {
          state.formState.daysPerServiceVariety[x as ServiceVarietyName] = [
            { day: DayOfWeek.Monday, isChecked: false },
            { day: DayOfWeek.Tuesday, isChecked: false },
            { day: DayOfWeek.Wednesday, isChecked: false },
            { day: DayOfWeek.Thursday, isChecked: false },
            { day: DayOfWeek.Friday, isChecked: false }
          ]
        }
      }
    },
    setSelectedProductIdsForService (state: IUserSelectionState, selectedProductIds: string[]) {
      Vue.set(state.formState, 'selectedProductIds', selectedProductIds)
      Vue.set(state, 'formIsValid', isFormValid(state))
    }
  }
}

function isFormValid (state : IUserSelectionState) : boolean {
  const formState = state.formState
  if (formState.selectedServiceKind === ServiceKind.SchoolCare) {
    if (formState.selectedSchool &&
      formState.selectedSchoolLocality &&
      formState.selectedSchoolGroup &&
      formState.daysPerServiceVariety.NSO &&
      (formState.daysPerServiceVariety.NSO.some(x => x.isChecked))) {
      return true
    }
  } else if (formState.daysPerServiceVariety.KDV && formState.daysPerServiceVariety.KDV.some(x => x.isChecked)) {
    return true
  }
  return false
}
