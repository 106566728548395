import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import Meta from 'vue-meta'
import { ServiceKind } from '@/models'
import i18n from '@/plugins/i18n'

declare module 'vue-router/types/router' {
  interface VueRouter {
    redirectToPartouSite(baseUrl: string, serviceKind: ServiceKind, mdmId: string): void
    buildRedirectToPartouSiteUrl(baseUrl: string, serviceKind: ServiceKind, mdmId: string) : string
  }
}

Vue.use(VueRouter)
Vue.use(Meta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: () => { return { x: 0, y: 0 } }
})

// Custom Router functions
router.buildRedirectToPartouSiteUrl = (baseUrl: string, serviceKind: ServiceKind, mdmId: string) => {
  const caretypeAsText = serviceKind === ServiceKind.DayCare ? i18n.t('externalWebsiteTranslations.caretypes.KDV').toString() : i18n.t('externalWebsiteTranslations.caretypes.BSO').toString()
  return baseUrl.replace('{mdmid}', mdmId).replace('{careType}', caretypeAsText)
}

router.redirectToPartouSite = (baseUrl: string, serviceKind: ServiceKind, mdmId: string) => {
  if (baseUrl && serviceKind && mdmId) {
    const url = router.buildRedirectToPartouSiteUrl(baseUrl, serviceKind, mdmId)
    window.open(url, '_self')
  }
}

export default router
