import moment from 'moment'
import IUserSelectionState from '../IUserSelectionState'
import { getStartDateOfCareMinDate } from '@/utils/dateUtils'

export function getIsValidStartDateOfCare (state : IUserSelectionState) : boolean {
  if (state.formState.dateOfBirth === undefined || state.formState.startDateOfDayCare === undefined) {
    return false
  }

  const startDateOfDayCare = moment(state.formState.startDateOfDayCare)

  const startDateOfCareMinDate = getStartDateOfCareMinDate(state.formState.dateOfBirth)

  if (startDateOfCareMinDate === undefined) {
    return false
  }

  return moment(startDateOfCareMinDate).isSameOrBefore(startDateOfDayCare)
}
