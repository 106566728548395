import { render, staticRenderFns } from "./ServiceOverviewPage.vue?vue&type=template&id=21f6af34&scoped=true&"
import script from "./ServiceOverviewPage.vue?vue&type=script&lang=ts&"
export * from "./ServiceOverviewPage.vue?vue&type=script&lang=ts&"
import style0 from "./ServiceOverviewPage.vue?vue&type=style&index=0&id=21f6af34&prod&lang=scss&"
import style1 from "./ServiceOverviewPage.vue?vue&type=style&index=1&id=21f6af34&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21f6af34",
  null
  
)

export default component.exports