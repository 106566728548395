
import { Component, Vue, Prop, Watch, Emit, Ref } from 'vue-property-decorator'
import PartouTextField from '@/components/PartouComponents/Input/PartouTextField/PartouTextField.vue'
import moment from 'moment'
import { regexes } from '@/definitions'
import { inputRegexDirective } from '@/utils/directives'
import PartouBasicDialog from '@/components/PartouComponents/PartouBasicDialog.vue'

// function that's being called by vuetify rules
type ruleFunction = (arg0: string) => (Promise<boolean | string>) | boolean | string;

const INPUT_TYPE_TEXT = 'text'
const INPUT_TYPE_DATE = 'date'
const DATE_FORMAT_YEAR_MONTH_DAY = 'YYYY-MM-DD'
const DATE_FORMAT_DAY_MONTH_YEAR = 'DD-MM-YYYY'

@Component({
  components: { PartouTextField, PartouBasicDialog },
  directives: { inputRegexDirective },
  data: () => ({ regexes }),
  methods: {
    moment: () => {
      return moment()
    }
  }
})
export default class DateInputField extends Vue {
  @Prop({ required: true, default: undefined })
  value! : Date | undefined

  @Prop({ required: false, default: false })
  isRequired!: boolean

  @Prop({ required: false, default: false })
  disabled!: boolean

  @Prop({ default: () => { return undefined } })
  minDate!: Date

  @Prop({ default: () => { return undefined } })
  maxDate!: Date

  @Prop({ required: false })
  errorMessageTranslationString?: string

  @Prop({ required: false })
  activePicker?: string

  @Prop({ required: false })
  placeholder?: string

  @Prop({ required: false, default: '' })
  label!: string

  @Prop({ required: false, default: false })
  showLabel!: boolean

  @Prop({ required: false, default: '' })
  autocomplete!: string

  @Prop({ required: false, default: () => [] })
  rules! : ruleFunction[]

  @Ref('pickerInput') pickerInput!: PartouTextField

  internalErrorMessage = this.errorMessageTranslationString
  date? : Date
  formattedDateString? : string
  showDialog = false

  inputValue : string | null = null
  inputPlaceholder? : string
  isCustomPlaceholder = false
  closeOnClickOutside = false
  isMobile = false
  inputType = INPUT_TYPE_DATE

  get currentDateString () : string {
    let currentDate = new Date()

    if (this.value) {
      currentDate = this.value
    } else if (moment(this.minDate).isAfter(currentDate)) {
      currentDate = this.minDate
    }

    return moment(currentDate).format(DATE_FORMAT_YEAR_MONTH_DAY)
  }

  getFormattedDateString (date : Date) : string {
    return moment(date).format(DATE_FORMAT_YEAR_MONTH_DAY)
  }

  created () : void {
    if (this.placeholder) {
      this.inputPlaceholder = this.isRequired ? `${this.placeholder} *` : this.placeholder
      this.isCustomPlaceholder = true
    } else {
      this.inputPlaceholder = this.isRequired ? `${moment(this.date).format(DATE_FORMAT_DAY_MONTH_YEAR)} *` : this.placeholder
    }
  }

  mounted () : void {
    if (this.inputValue === null) {
      this.inputType = INPUT_TYPE_TEXT
    }
    window.addEventListener('keyup', this.keyup)
    if (this.pickerInput) {
      (this.pickerInput as any).onMouseDown = (e : Event) => { /* Disabled because of any: onMouseDown does not exist on Vuecomponent type */// eslint-disable-line
        e.preventDefault()
      }
    }
  }

  updated () : void {
    this.isMobile = this.$vuetify.breakpoint.xs
    if (this.isMobile && this.inputType === INPUT_TYPE_DATE) {
      this.inputType = INPUT_TYPE_TEXT
      this.formatInputValue(moment.utc(this.inputValue, DATE_FORMAT_YEAR_MONTH_DAY))
    }
    if (!this.isMobile && this.inputType === INPUT_TYPE_TEXT && this.inputValue !== null) {
      this.inputType = INPUT_TYPE_DATE
      this.formatInputValue(moment.utc(this.inputValue, DATE_FORMAT_DAY_MONTH_YEAR))
    }
  }

  keyup (evt: KeyboardEvent) : void {
    if (evt.key === 'Escape' || evt.key === 'Esc') {
      this.showDialog = false
    }
  }

  @Watch('value', { immediate: true })
  onValueChanged () : void {
    this.date = this.value
    if (this.inputValue === null && this.value) {
      this.formatInputValue(moment(new Date(this.value)))
    }
    this.formattedDateString = this.value ? moment(new Date(this.value)).format(DATE_FORMAT_YEAR_MONTH_DAY) : ''
  }

  changeToDateType () : void {
    if (!this.isMobile) {
      this.inputType = INPUT_TYPE_DATE
    }
  }

  onInputValueChanged (value: string) : void {
    if (value && value.length > 0) {
      this.inputValue = value
      let dateValue: moment.Moment
      if (this.isMobile) {
        dateValue = moment.utc(value, DATE_FORMAT_DAY_MONTH_YEAR)
      } else {
        dateValue = moment.utc(value, DATE_FORMAT_YEAR_MONTH_DAY)
      }

      if (dateValue.isValid()) {
        this.onDateChanged(dateValue.toDate())
      }
    } else {
      this.inputValue = null
      this.onDateChanged(undefined)
    }
  }

  onInputBlur () : void {
    if (this.inputValue) {
      let dateValue: moment.Moment
      if (this.isMobile) {
        dateValue = moment.utc(this.inputValue, DATE_FORMAT_DAY_MONTH_YEAR)
      } else {
        dateValue = moment.utc(this.inputValue, DATE_FORMAT_YEAR_MONTH_DAY)
      }

      if (dateValue.isValid()) {
        this.formatInputValue(dateValue)
      }
    }
  }

  formatInputValue (moment: moment.Moment) : void {
    if (this.isMobile) {
      this.inputValue = moment.format(DATE_FORMAT_DAY_MONTH_YEAR)
    } else {
      this.inputValue = moment.format(DATE_FORMAT_YEAR_MONTH_DAY)
    }
  }

  isValidDate (value : string) : boolean | string {
    let dateValue: moment.Moment
    if (this.isMobile) {
      dateValue = moment(value, DATE_FORMAT_DAY_MONTH_YEAR)
    } else {
      dateValue = moment(value, DATE_FORMAT_YEAR_MONTH_DAY)
    }
    if (!dateValue.isValid()) {
      return this.$t('form.invalidDate').toString()
    } else if (this.minDate && dateValue.isBefore(this.minDate, 'day')) {
      return this.$t('form.dateBeforeMinDate', { date: moment(this.minDate).format(DATE_FORMAT_DAY_MONTH_YEAR) }).toString()
    } else if (this.maxDate && dateValue.isAfter(this.maxDate, 'day')) {
      return this.$t('form.dateAfterMaxDate', { date: moment(this.maxDate).format(DATE_FORMAT_DAY_MONTH_YEAR) }).toString()
    }
    return true
  }

  @Watch('showDialog')
  onModalChanged () : void {
    if (this.showDialog && this.activePicker) {
      setTimeout(() => ((this.$refs.picker as any).activePicker = this.activePicker)) // eslint-disable-line @typescript-eslint/no-explicit-any, no-unused-expressions
    }
  }

  @Watch('errorMessageTranslationString', { immediate: true })
  onErrorMessageTranslationStringChanged () : void {
    this.internalErrorMessage = this.errorMessageTranslationString
  }

  activateModal () : void {
    this.showDialog = true
  }

  @Emit('input')
  onDateChanged (date : Date | undefined) : Date | undefined {
    this.date = date
    this.formattedDateString = date ? moment(date).format(DATE_FORMAT_YEAR_MONTH_DAY) : undefined

    this.showDialog = false

    return this.date
  }

  onDateSelected (value : string) : void {
    const date = new Date(value)
    this.inputValue = null
    this.onDateChanged(date)
  }

  onClickOutside () : void {
    this.showDialog = false
  }

  formatCellHeader (date : Date) : string {
    const formattedString = date ? moment(date).locale('nl').format('dd') : ''
    return formattedString.charAt(0).toUpperCase() + formattedString.substring(1)
  }

  formatHeader (date : Date) : string {
    const formattedString = date ? moment(date).locale('nl').format('MMMM YYYY') : ''
    return formattedString.charAt(0).toUpperCase() + formattedString.substring(1)
  }

  disableOpenCalendar (value : Event) : void {
    if (!this.isMobile) {
      value.preventDefault()
    }
  }

  formatTitle (date : Date) : string {
    const formattedString = date ? moment(date).locale('nl').format('dd DD MMM') : ''
    return formattedString.charAt(0).toUpperCase() + formattedString.substring(1)
  }
}
