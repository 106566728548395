import DayCheckboxState from '@/components/InputFields/DaySelector/DayCheckboxState'
import { Proposition, ServiceKind, ServiceVarietyOpening } from '@/models'
import IUserSelectionState from '../IUserSelectionState'
import DaySelectionState, { getInitialDaySelectionState } from '@/models/types/DaySelectionState'
import DayCheckboxType from '@/components/InputFields/DaySelector/DayCheckboxType'
import { isBetween } from '@/utils/dateUtils'
import ServiceVarietyProposition from '@/models/ServiceVarietyProposition'
import { NAMESPACE as PROPOSITION_NAMESPACE, GETTERS as PROPOSITION_GETTERS } from '@/store/modules/proposition'
import ServiceVarietyName, { sortedServiceVarietyNames } from '@/models/enums/ServiceVarietyName'

export function getDayCheckboxState (state : IUserSelectionState, rootGetters :any, withAvailability: boolean, withOpeningHours: boolean, offerId?: string) { // eslint-disable-line @typescript-eslint/no-explicit-any
  const daycheckboxState: Record<ServiceVarietyName, DayCheckboxState[]> = {
    KDV: getInitialDaySelectionState().map((x, index) => ({ day: x.day, type: DayCheckboxType.Check, isChecked: state.formState.selectedServiceKind === ServiceKind.DayCare ? state.formState.daysPerServiceVariety.KDV[index]?.isChecked ?? false : false, isReadOnly: false, priority: x.priority })),
    NSO: getInitialDaySelectionState().map((x, index) => ({ day: x.day, type: DayCheckboxType.Check, isChecked: state.formState.selectedServiceKind === ServiceKind.SchoolCare ? state.formState.daysPerServiceVariety.NSO[index]?.isChecked ?? false : false, isReadOnly: false, priority: x.priority })),
    VSO: getInitialDaySelectionState().map((x, index) => ({ day: x.day, type: DayCheckboxType.Check, isChecked: state.formState.selectedServiceKind === ServiceKind.SchoolCare ? state.formState.daysPerServiceVariety.VSO[index]?.isChecked ?? false : false, isReadOnly: false, priority: x.priority }))
  }

  let proposition : Proposition | undefined
  if (withAvailability) {
    const getPropositionById : (offerId: string) => Proposition | undefined = rootGetters[`${PROPOSITION_NAMESPACE}/${PROPOSITION_GETTERS.getPropositionById}`]
    proposition = state.formState.selectedPropositionId || offerId ? getPropositionById((offerId ?? state.formState.selectedPropositionId ?? '')) : undefined
  } else if (withOpeningHours) {
    proposition = state.formState.selectedService as unknown as Proposition
  }

  if (proposition) {
    uncheckDaysOfMissingOfferedServiceVarietyInProposition(daycheckboxState, proposition)
    if (withAvailability) {
      calculateAvailabiltyforDays(daycheckboxState, proposition)
    }
    if (withOpeningHours) {
      calculateClosedDays(daycheckboxState, proposition)
    }
  }

  calculateDisabledDays(daycheckboxState, state)

  return daycheckboxState
}

function uncheckDaysOfMissingOfferedServiceVarietyInProposition (daycheckboxState: Record<ServiceVarietyName, DayCheckboxState[]>, proposition : Proposition) : Record<ServiceVarietyName, DayCheckboxState[]> {
  sortedServiceVarietyNames.forEach(serviceVarietyName => {
    if ((proposition.offeredServiceVarieties && !proposition.offeredServiceVarieties.some(x => x.toString() === serviceVarietyName.toString())) || !proposition.serviceVarieties.flatMap(x => x.name).some(x => x === serviceVarietyName)) {
      daycheckboxState[serviceVarietyName].forEach(x => { x.isChecked = false })
    }
  })
  return daycheckboxState
}

function calculateDisabledDays (daycheckboxState: Record<ServiceVarietyName, DayCheckboxState[]>, state : IUserSelectionState) {
  if (state.formState.selectedServiceKind === ServiceKind.SchoolCare) {
    daycheckboxState.NSO.forEach((x, index) => {
      if (!x.isChecked) {
        daycheckboxState.VSO[index].isDisabled = true
        daycheckboxState.VSO[index].isChecked = false
      }
    })
  }
}

function calculateAvailabiltyforDays (daycheckboxState: Record<ServiceVarietyName, DayCheckboxState[]>, proposition : Proposition) {
  for (const serviceVariety of proposition.serviceVarieties) {
    daycheckboxState[serviceVariety.name as ServiceVarietyName].forEach(
      x => { x.type = getAvailabilityForDay(x, proposition.serviceVarietyPropositions?.filter(x => x.serviceVarietyName === serviceVariety.name)) }
    )
  }
}

function getAvailabilityForDay (daySelectionState: DaySelectionState, serviceVarietyProposition: ServiceVarietyProposition[] | undefined) {
  if (serviceVarietyProposition) {
    const value = serviceVarietyProposition[0]?.availabilityPerDay[daySelectionState.day]
    if (!value) {
      return DayCheckboxType.Wait
    }
  }
  return DayCheckboxType.Check
}

function calculateClosedDays (daycheckboxState: Record<ServiceVarietyName, DayCheckboxState[]>, proposition : Proposition) {
  for (const serviceVariety of proposition.serviceVarieties) {
    const currentOpening = serviceVariety.serviceVarietyOpenings.filter(opening => isBetween(opening.validFrom, opening.validUntil) && opening.type === 'School')[0] ?? undefined

    daycheckboxState[serviceVariety.name as ServiceVarietyName].forEach(
      x => {
        const closed = getOpeningForDay(x, currentOpening)
        if (closed) {
          x.type = closed
          x.isChecked = false
        }
      }
    )
  }
}

const daysForCheckboxes: (keyof ServiceVarietyOpening)[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
function getOpeningForDay (daySelectionState: DaySelectionState, openings?: ServiceVarietyOpening) : DayCheckboxType | undefined {
  if (!openings) {
    return DayCheckboxType.Closed
  }

  const dayString = daysForCheckboxes.find(y => y === daySelectionState.day.toLowerCase())
  if (!dayString) {
    throw new Error('Did not find day ' + daySelectionState.day.toLowerCase())
  }

  const dayOpenings = openings[dayString]

  if (!dayOpenings || dayOpenings.length === 0) {
    return DayCheckboxType.Closed
  }
}
