import IPropositionState, { STATE } from './IPropositionState'
import Vue from 'vue'
import { MutationTree } from 'vuex'
import { GetWaitingListStatusOutput, Proposition } from '@/models'
import { state as createDefaultState } from './index'
import PropositionSubscription from '@/models/PropositionSubscription'
import { BookPropositionRequest } from '@/services/BookingService/IBookingService'
import IBookPropositionState from './IBookPropositionState'
import { AcceptProposedWaitingListPropositionRequest } from '@/services/WaitingListService/IWaitingListService'

export const MUTATIONS = {
  setPropositions: 'setPropositions',
  updateProposition: 'updateProposition',
  setPropositionSubscriptions: 'setPropositionSubscriptions',
  setWaitingListPropositionStatus: 'setWaitingListPropositionStatus',
  setAcceptProposedWaitingListPropositionState: 'setAcceptProposedWaitingListPropositionState',
  setBookPropositionState: 'setBookPropositionState',
  setAcceptProposedWaitingListPropositionRequest: 'setAcceptProposedWaitingListPropositionRequest',
  setBookPropositionRequest: 'setBookPropositionRequest',
  resetState: 'resetState'
}

export function mutations (): MutationTree<IPropositionState> {
  return {
    setPropositions (state: IPropositionState, propositions: Array<Proposition>) {
      const newPropositionHistory = state.propositions?.filter(x => !propositions.some(y => y.id === x.id)) ?? []

      if (state.propositionHistory && state.propositionHistory.length > 0) {
        const allAddedPropositions = propositions.concat(newPropositionHistory)
        // Push all propositions that were already in history
        newPropositionHistory.push(...state.propositionHistory.filter(x => !allAddedPropositions.some(y => y.id === x.id)))
      }

      Vue.set(state, STATE.propositions, propositions)
      Vue.set(state, STATE.propositionHistory, newPropositionHistory)
    },
    updateProposition (state : IPropositionState, proposition : Proposition) {
      if (state.propositions) {
        const index = state.propositions.findIndex(p => p.id === proposition.id)

        if (index !== -1) {
          const newProposition = { ...state.propositions[index], ...proposition }
          Vue.set(state.propositions, index, newProposition)
        } else {
          const propositions = state.propositions.concat([proposition])

          Vue.set(state, STATE.propositions, propositions)
        }
      } else {
        Vue.set(state, STATE.propositions, [proposition])
      }
    },
    setPropositionSubscriptions (state : IPropositionState, data : { offerId: string, propositionSubscriptions: PropositionSubscription[] }) {
      if (state.propositions) {
        const index = state.propositions.findIndex(p => p.id === data.offerId)

        if (index !== -1) {
          state.propositions[index].propositionSubscriptions = data.propositionSubscriptions
          Vue.set(state.propositions, index, state.propositions[index])
        }
      }
    },
    setWaitingListPropositionStatus (state : IPropositionState, status : GetWaitingListStatusOutput) {
      Vue.set(state, STATE.waitinglistPropositionStatus, status)
    },
    setAcceptProposedWaitingListPropositionState (state : IPropositionState, request: AcceptProposedWaitingListPropositionRequest) {
      Vue.set(state, STATE.acceptProposedWaitingListPropositionState, { ...state.acceptProposedWaitingListPropositionState, ...request })
    },
    setAcceptProposedWaitingListPropositionRequest (state : IPropositionState, request: AcceptProposedWaitingListPropositionRequest) {
      Vue.set(state, STATE.acceptProposedWaitingListPropositionState, {
        pending: true,
        request,
        responseAsync: undefined
      })
    },
    setBookPropositionRequest (state : IPropositionState, request: BookPropositionRequest) {
      Vue.set(state, STATE.bookPropositionState, {
        pending: true,
        request,
        responseAsync: undefined
      })
    },
    setBookPropositionState (state: IPropositionState, BookPropositionRequest: Partial<IBookPropositionState>) {
      Vue.set(state, STATE.bookPropositionState, { ...state.bookPropositionState, ...BookPropositionRequest })
    },
    resetState (state: IPropositionState) {
      Object.assign(state, createDefaultState())
    }
  }
}
