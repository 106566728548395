import { Module } from 'vuex'
import IState from '@/store/IState'
import IPropositionState, { STATE } from '@/store/modules/proposition/IPropositionState'
import { actions, ACTIONS } from './propositionActions'
import { mutations, MUTATIONS } from './propositionMutations'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import IPropositionService from '@/services/PropositionService/IPropositionService'
import IOfferService from '@/services/OfferService/IOfferService'
import { getters, GETTERS } from './propositionGetters'
import IWaitingListService from '@/services/WaitingListService/IWaitingListService'
import IBookingService from '@/services/BookingService/IBookingService'

export function state (): IPropositionState {
  return {
    propositions: undefined,
    propositionHistory: undefined,
    bookPropositionState: {
      pending: false
    },
    acceptProposedWaitingListPropositionState: {
      pending: false
    }
  }
}

export function module (): Module<IPropositionState, IState> {
  const propositionService = container.get<IPropositionService>(SERVICE_IDENTIFIERS.IPropositionService)
  const offerService = container.get<IOfferService>(SERVICE_IDENTIFIERS.IOfferService)
  const waitingListService = container.get<IWaitingListService>(SERVICE_IDENTIFIERS.IWaitingListService)
  const bookingService = container.get<IBookingService>(SERVICE_IDENTIFIERS.IBookingService)

  return {
    namespaced: true,
    state,
    mutations: mutations(),
    actions: actions(waitingListService, propositionService, offerService, bookingService),
    getters: getters()
  }
}

export const NAMESPACE = 'proposition'
export { ACTIONS }
export { MUTATIONS }
export { STATE }
export { GETTERS }
